// Variable overrides
// Typography
$primary-font: 'Fira Sans', sans-serif;
$secondary-font: 'Source Sans Pro', sans-serif;

$font-weight-900: 900;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-400: 400;
$font-weight-300: 300;

$font-40: 40px;
$font-36: 36px;
$font-28: 28px;
$font-24: 24px;
$font-20: 20px;
$font-18: 18px;
$font-14: 14px;

// Colors
$primary-1: #ffffff;
$primary-2: #000000;
$white: white;
$white: white;

$secondary-1: #f3c716;
$secondary-2: #ffe293;
$secondary-3: #ffefc6;
$netural: #f0f0f0;


$black-1: #09112F;

$black: #0F172A;
$turquoise: #4ad0ee;
$golden: #fec400;
$coral-red: #F44336;
$dark-grey: #5f6985;
$apple-green: #4CAF50;
$bg-100: linear-gradient(167.96deg, #05103A 0%, #0C1433 49.79%, #000000 100%);
$bg-200: #0D1A46;
$bg-300: #14224F;
$bg-400: #293560;
$bg-500: #245E7D;
$light-grey: #878EA0;
$scrollbar-track-dark: #20294C;
$scrollbar-track-light: #F3F4F5;
$scrollbar-thumb: #878ea033;
$primary-btn-hover: #3FB2CC;
$secondary-btn-hover: #6C7180;
$light-grey-bg: #F5F6F7;
$dark-blue: #071138;


$gray: grey;

$grey-darkest: #475569;
$grey-dark: #94A3B8;
$grey: #CBD5E1;
$grey-light: #E2E8F0;
$grey-light-1: #F6F5F5;
$grey-lightest: #F1F5F9;
$grey-1: #CBCBCB;




$grey-2: #9F9F9F;
$mild-dark: #4F4F4F;
$mild-darkest: #333333;
$table-bg-accent: $grey-light-1;
$indigo: #293560;

$light-grey-1: #ddd;
$light-grey-2: #E5E5E5;
$grey-2: #EFF0F3;


$stroke: linear-gradient(133.98deg, #070E29 16.55%, #0B1334 86.38%);
$border: #070E29;
/* $table-striped-order: $grey-light-1; */

$success: $apple-green;
$success-bg: #F1F9F1;

$danger: #F43F5E;
$danger-bg: #FEF0EF;

$warning: #FFC107;
$warning-bg: #FFFAEB;

$turquoise-bg: #F1FBFE;

$hyperlink: #1E40AF;
$info: #1E40AF;
$danger-1: #BE123C;


// Buttons

$btn-border-primary: $turquoise;
$btn-background-primary: $turquoise;
$btn-hover-background-primary: $turquoise;
$btn-text-color-primary: $white;

$btn-border-error: $danger-1;
$btn-background-error: $white;
$btn-hover-background-error: $danger;
$btn-text-color-error: $danger-1;

$btn-lg-text-font-size: 24px;
$btn-md-text-font-size: 18px;
$btn-sm-text-font-size: 14px;

$btn-font-family: 'Source Sans Pro', sans-serif;
$btn-font-weight: $font-weight-600;

// logo

$shared-studios-logo: '../assets/logo/logo.svg';
$header-height: 0px;
$header-light-bg: $primary-2;
//body
$body-bg: $white;
$body-color: $primary-2;
$grid-gutter-width: 0px;
$font-family-base: $primary-font;

$breadcrumb-divider: quote('\e905') !default;

$hover-border: 0.0625rem solid #f2f2f2;
$hover-box-sizing: border-box;
$hover-box-shadow: -0.375rem 0.375rem 0.625rem rgba(109, 109, 109, 0.1);

$avatar-width: 3.25rem;
$avatar-status-width: 3.25rem;
$sidebar-nav-link-padding-y: 1.1rem;
$sidebar-nav-link-padding-x: 1rem;

$border-radius: 0.5rem;
$sidebar-width: 250px;
$sidebar-minimized-width: 70px;
$disabled-opacity: 0.8;
$list-hover: #878ea014;
