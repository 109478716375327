
.c-sidebar{
  &.c-sidebar-light{
    color: $primary-2;
    background-color: $bg-200 !important;
    border: none !important;
    .c-sidebar-brand{
      background: none !important;
    }
  }
}
