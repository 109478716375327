@import '../variables';

.popover-custom-container {
  color: $black-1 !important;
  border: none !important;
  box-shadow: 0px 10px 40px rgba(109, 125, 145, 0.2);

  .popover-arrow-container {
    padding: 0 !important;
    color: $black-1;

    .popover-arrow {
      border-right: 6px solid $white !important;
      opacity: 1 !important;
    }

    .popover-container {
      background-color: $white;
      opacity: 1;
      height: auto;
      width: 16.438rem;
      border-radius: $border-radius;

      .dd-list {
        list-style: none;
        padding: 1.875rem;
        padding-bottom: 0;

        .org-name,
        .email-ellip {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 10.3rem;
        }

        li {
          border-bottom: 1px solid $grey-2;

          &.logout,
          &.app-name {
            padding: 0.8rem 0 !important;

            &:hover {
              color: $grey-dark;
              cursor: pointer;
              user-select: none;
            }

            &.disabled {
              pointer-events: none;
              opacity: 0.7;
            }
          }
        }

        li:last-child {
          border-bottom: none !important;
        }

        .dd-btn-toggle {
          width: 12.688rem;
          border: 1px solid $grey-2 !important;
          color: $black-1 !important;

          &:focus,
          &:active,
          &:hover {
            border: 1px solid $grey-2 !important;
            box-shadow: none !important;
          }

          &::after {
            vertical-align: 1.4rem !important;
            margin-left: 0 !important;
          }
        }

        .dropdown-menu {
          width: 12.688rem;
          padding: 0 !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;

          & li:hover {
            background-color: $light-grey-bg;
          }

          & li {
            border-bottom: 1px solid $light-grey;
          }

          & li:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}