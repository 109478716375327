@font-face {
  font-family: 'icomoon';
  src:  url('../assets/icons/shared-studios-icons/icomoon.eot?tn1t38');
  src:  url('../assets/icons/shared-studios-icons/icomoon.eot??tn1t38#iefix') format('embedded-opentype'),
    url('../assets/icons/shared-studios-icons/icomoon.ttf??tn1t38') format('truetype'),
    url('../assets/icons/shared-studios-icons/icomoon.woff??tn1t38') format('woff'),
    url('../assets/icons/shared-studios-icons/icomoon.svg??tn1t38#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* [class^="icon-"], [class*=" icon-"] {
  //use !important to prevent issues with browser extensions that change fonts
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  //Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\e900";
  color: #fff;
}
.icon-camera:before {
  content: "\e901";
  color: #fff;
}
.icon-camera-alt:before {
  content: "\e902";
}
.icon-caret-right-black:before {
  content: "\e903";
}
.icon-check-circle:before {
  content: "\e904";
}
.icon-chevron-down:before {
  content: "\e905";
}
.icon-chevron-left:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-chevron-up:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e909";
  color: #fff;
}
.icon-container:before {
  content: "\e90a";
}
.icon-cross-black:before {
  content: "\e90b";
}
.icon-display:before {
  content: "\e90c";
  color: #fff;
}
.icon-down:before {
  content: "\e90d";
  color: #fff;
}
.icon-filter:before {
  content: "\e90e";
}
.icon-forum:before {
  content: "\e90f";
}
.icon-left:before {
  content: "\e910";
  color: #fff;
}
.icon-maximize-2:before {
  content: "\e911";
}
.icon-messages:before {
  content: "\e912";
  color: #fff;
}
.icon-mic:before {
  content: "\e913";
}
.icon-notifications:before {
  content: "\e914";
  color: #fff;
}
.icon-partner:before {
  content: "\e915";
}
.icon-people:before {
  content: "\e916";
}
.icon-right:before {
  content: "\e917";
  color: #fff;
}
.icon-rotate:before {
  content: "\e918";
  color: #fff;
}
.icon-rotate-cw:before {
  content: "\e919";
}
.icon-rotate-reverse:before {
  content: "\e91a";
  color: #fff;
}
.icon-spanner-setting:before {
  content: "\e91b";
}
.icon-speaker:before {
  content: "\e91c";
}
.icon-straight-left:before {
  content: "\e91d";
  color: #fff;
}
.icon-straight-right:before {
  content: "\e91e";
  color: #fff;
}
.icon-tick-white:before {
  content: "\e91f";
  color: #fff;
}
.icon-tilt-left:before {
  content: "\e920";
  color: #fff;
}
.icon-tilt-right:before {
  content: "\e921";
  color: #fff;
}
.icon-up:before {
  content: "\e922";
  color: #fff;
}
.icon-videocam:before {
  content: "\e923";
}
.icon-warning-amber:before {
  content: "\e924";
  color: #fcd34d;
}
.icon-zoom-in:before {
  content: "\e925";
  color: #fff;
}
.icon-zoom-out:before {
  content: "\e926";
  color: #fff;
}
 */
 [class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Property-1Collapse .path1:before {
  content: "\e925";
  color: rgb(74, 208, 238);
}
.icon-Property-1Collapse .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Property-1Expand .path1:before {
  content: "\e927";
  color: rgb(74, 208, 238);
}
.icon-Property-1Expand .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-add:before {
  content: "\e929";
  color: #878ea0;
}
.icon-archive:before {
  content: "\e92a";
  color: #878ea0;
}
.icon-arrow-down:before {
  content: "\e92b";
  color: #878ea0;
}
.icon-camera:before {
  content: "\e92c";
  color: #878ea0;
}
.icon-clear:before {
  content: "\e92d";
  color: #878ea0;
}
.icon-close1:before {
  content: "\e92e";
  color: #878ea0;
}
.icon-display:before {
  content: "\e92f";
  color: #878ea0;
}
.icon-edit:before {
  content: "\e930";
  color: #878ea0;
}
.icon-events:before {
  content: "\e931";
  color: #878ea0;
}
.icon-info:before {
  content: "\e932";
  color: #878ea0;
}
.icon-maximize:before {
  content: "\e933";
  color: #878ea0;
}
.icon-menu:before {
  content: "\e934";
  color: #878ea0;
}
.icon-minimize:before {
  content: "\e935";
  color: #878ea0;
}
.icon-move-down:before {
  content: "\e936";
  color: #878ea0;
}
.icon-move-left:before {
  content: "\e937";
  color: #878ea0;
}
.icon-move-right:before {
  content: "\e938";
  color: #878ea0;
}
.icon-move-up:before {
  content: "\e939";
  color: #878ea0;
}
.icon-nodes:before {
  content: "\e93a";
  color: #878ea0;
}
.icon-redo:before {
  content: "\e93b";
  color: #878ea0;
}
.icon-reset:before {
  content: "\e93c";
  color: #878ea0;
}
.icon-rotate-x-left:before {
  content: "\e93d";
  color: #878ea0;
}
.icon-rotate-x-right:before {
  content: "\e93e";
  color: #878ea0;
}
.icon-rotate-y-down:before {
  content: "\e93f";
  color: #878ea0;
}
.icon-rotate-y-up:before {
  content: "\e940";
  color: #878ea0;
}
.icon-save:before {
  content: "\e941";
  color: #878ea0;
}
.icon-search:before {
  content: "\e942";
  color: #878ea0;
}
.icon-sort:before {
  content: "\e943";
  color: #878ea0;
}
.icon-Straighten-1:before {
  content: "\e944";
  color: #878ea0;
}
.icon-Straighten-2:before {
  content: "\e945";
  color: #878ea0;
}
.icon-tilt-down:before {
  content: "\e946";
  color: #878ea0;
}
.icon-tilt-up:before {
  content: "\e947";
  color: #878ea0;
}
.icon-tilt-x-left:before {
  content: "\e948";
  color: #878ea0;
}
.icon-tilt-x-right:before {
  content: "\e949";
  color: #878ea0;
}
.icon-tilt-y-down:before {
  content: "\e94a";
  color: #878ea0;
}
.icon-tilt-y-up:before {
  content: "\e94b";
  color: #878ea0;
}
.icon-undo:before {
  content: "\e94c";
  color: #878ea0;
}
.icon-update:before {
  content: "\e94d";
  color: #878ea0;
}
.icon-users:before {
  content: "\e94e";
  color: #878ea0;
}
.icon-warning:before {
  content: "\e94f";
  color: #878ea0;
}
.icon-zoom-in1:before {
  content: "\e950";
  color: #878ea0;
}
.icon-zoom-out1:before {
  content: "\e951";
  color: #878ea0;
}
.icon-check-circle:before {
  content: "\e903";
}
