//breadcrumb
.breadcrumb-custom {
    .breadcrumb-item {
        font-family: $primary-font;
        text-decoration: underline;
        &.active {
            color: $white;
            font: normal $font-weight-400 1rem/1.5rem $primary-font;
            text-decoration: none;
        }
        a {
            color: $white;
            font: normal $font-weight-400 1rem/1.5rem $primary-font;
        }
        &::before {
            padding-right: 0.6rem !important;
            padding-left: 0.2rem;
            content: '>';
            font-family: 'icomoon', sans-serif;
            color: $white;
            font-size: 0.8rem;
            align-self: center;
        }
        &:first-child {
            &::before{
                content: none !important;
            }
        }
    }
}