// sidebar
.custom-sidebar.c-sidebar.c-sidebar-fixed {
    top: $header-height !important;
    /* width: 250px; */
    .c-sidebar-nav{
      &.routes-list{
        margin-top: 5rem;
      }
      a:hover{
        width: auto;
        background: none;
        color: $turquoise;
      }
    }
    .c-sidebar-brand{
      .full-logo {
        /* filter: brightness(100); */
        width: 100px;
      }
      .just-logo {
        /* filter: brightness(100); */
        width: 26px;
      }
    }
    .c-sidebar-nav-item{
      .c-sidebar-nav-link {
        border-left: 5px solid transparent;
        color: $light-grey;
        padding: 1.1rem 1.15rem !important;

        .c-sidebar-nav-icon {
            color: $turquoise !important;
        }
        &:active, &.c-active {
          border-left-color: $turquoise;
          background: none !important;
          color: $turquoise !important;
          ::before {
            color:$turquoise !important;
          }
        }
        &:hover {
            background: none !important;
            border-left-color: $turquoise;
            color: $turquoise !important;
            ::before {
              color:$turquoise !important;
            }
        }
        &.disabled {
            /* background: transparent !important; */
            pointer-events: none;
            color: $light-grey !important;
            cursor: pointer;
        }
      }
    }

    .c-sidebar-nav-dropdown{
        &:hover {
            background: $white !important;
        }
        .custom .c-sidebar-nav-link{
            padding-left: 2rem;
            &:hover {
                background: rgba(0, 0, 0, 0.05) !important;
            }
        }
    }
}
@media screen and (max-width: 991.98px) {
    .custom-sidebar {
        margin-left: 0 !important;
        width: 3.5rem !important;
    }
}
