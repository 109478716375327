.btn-custom {
  @include border-radius(0px);
  background-color: $btn-background-primary;
  border: 1px solid $btn-background-primary  !important;
  padding: 0.66rem 2rem; //lg button
  font: normal $font-weight-600 1rem/1.6875rem $primary-font;
  letter-spacing: 0.002em;
  color: $btn-text-color-primary;
  border-radius: 1.5rem;
  box-sizing: border-box;

  &.btn-width {
    width: -webkit-fill-available;
  }

  &.x-lg {
    height: 3.75rem;
    border-radius: 30px;
  }

  &.lg {
    height: 3rem;
  }

  &.md {
    height: 2.5rem;
  }

  &.btn-primary {
    border-bottom-color: transparent !important;
    border-right-color: transparent;
    border-bottom-width: 0px;
    border-right-width: 0;
    cursor: pointer;

    &:hover {
      background: $primary-btn-hover;
    }

    &:active,
    &:focus {
      background-color: $btn-background-primary  !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }

    &:disabled,
    &.disabled {
      background-color: $light-grey  !important;
      border-color: transparent !important;
      box-shadow: none !important;
      pointer-events: none;
      cursor: default;
    }
  }

  &.btn-secondary {
    background: transparent;
    border-color: $btn-background-primary  !important;
    color: $btn-background-primary  !important;

    &:hover {
      background: transparent !important;
      color: $primary-btn-hover  !important;
      border-color: $primary-btn-hover  !important;
    }

    &:active,
    &:focus {
      background-color: transparent !important;
      border-color: $primary-btn-hover  !important;
      box-shadow: none !important;
      color: $primary-btn-hover  !important;
    }

    &:disabled,
    &.disabled {
      background-color: transparent !important;
      border-color: $light-grey  !important;
      box-shadow: none !important;
      pointer-events: none;
      color: $light-grey  !important;
      cursor: default;
    }
  }

  &.btn-outline {
    background: none !important;
    border-color: $btn-background-primary  !important;
    color: $btn-background-primary  !important;

    &:hover {
      background: transparent !important;
      color: $primary-btn-hover  !important;
      border-color: $primary-btn-hover  !important;
    }

    &:active,
    &:focus {
      background-color: transparent !important;
      border-color: $primary-btn-hover  !important;
      box-shadow: none !important;
      color: $primary-btn-hover  !important;
    }

    &:disabled,
    &.disabled {
      background-color: transparent !important;
      border-color: $light-grey  !important;
      box-shadow: none !important;
      pointer-events: none;
      color: $light-grey  !important;
      cursor: default;
    }
  }

  &.btn-link {
    background: transparent;
    border: none !important;
    color: $btn-background-primary  !important;
    padding: 0;
    margin: 0;
  }

  &.btn-error {
    font-size: $btn-lg-text-font-size  !important;
    background-color: $btn-background-error;
    border: 1px solid $danger-1  !important;
    color: $btn-text-color-error;

    &:hover {
      background: $grey-lightest;
    }

    &:active,
    &:focus {
      background-color: $btn-background-error  !important;
      border-color: $btn-background-error  !important;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: $btn-background-error  !important;
      border-color: $btn-background-error  !important;
      box-shadow: none !important;
      opacity: 0.65;
      pointer-events: none;
      cursor: default;
    }

  }

  &.btn-lg {
    padding: 0.407rem 1.250rem;
    font-size: $btn-lg-text-font-size  !important;
  }

  &.btn-sm {
    padding: 0.288rem 1.250rem;
    font-size: $btn-sm-text-font-size  !important
  }

  &.btn-md {
    padding: 0.363rem 1.250rem;
    font-size: $btn-md-text-font-size  !important;
  }

  &.btn-hover {
    background: $btn-hover-background-primary  !important;
  }

  &.with-text {
    span:not(.text) {
      margin-right: 0.2rem;
      vertical-align: middle;

      i {
        font-size: 1.2rem;

        &::before {
          color: $white;
        }
      }
    }
  }
}

.btn-filter {
  height: 2.25rem;
  width: 5.875rem;
  border: 1px solid $primary-2;
  border-radius: 2px;

  &span {
    margin-right: 0.4rem;
    vertical-align: middle;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border: none;
  border-radius: 50%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 2rem;

    &::before {
      color: $white;
    }
  }

  &.turquoise {
    &.enabled {
      background-color: $turquoise;

      :hover {
        background-color: $primary-btn-hover;
      }
    }

    &.disabled,
    &:disabled {
      background-color: $turquoise;
      opacity: $disabled-opacity;
      cursor: default;
      pointer-events: none;
    }
  }

  &.grey {
    background-color: $light-grey;
  }

  &.active {
    background: $turquoise;
    box-shadow: 0px 10px 16px rgba(74, 208, 238, 0.16);
  }

  &.enabled {
    background: $light-grey;
    border: none;

    i::before {
      color: $white;
      cursor: pointer;
    }

    &:hover {
      background: $secondary-btn-hover;
    }
  }

  &.disabled {
    background: $light-grey;
    border: none;
    opacity: 1;
    pointer-events: none;
    cursor: default;
    pointer-events: none;

    i::before {
      opacity: $disabled-opacity;
      cursor: default;
    }
  }

  &.btn-circle-sm1 {
    height: 3.75rem; //60px
    width: 3.75rem;
    border: none;

    i {
      font-size: 2rem;
    }
  }

  &.btn-circle-sm4 {
    height: 1.875rem; //30px
    width: 1.875rem;
    border: none;
    padding: 0;

    i {
      font-size: 1.1rem; // 17.60px
    }

    &.left-margin {
      margin-left: 10px;
    }

    &.right-margin {
      margin-right: 10px;
    }

    &.top-margin {
      margin-top: 10px;
    }

    &.bottom-margin {
      margin-bottom: 10px;
    }
  }

  &.btn-circle-sm2 {
    height: 2.75rem; //44px
    width: 2.75rem;
    border: none;

    i {
      font-size: 1.5rem;
    }
  }

  &.btn-circle-sm3 {
    height: 3.313rem; //53.008px
    width: 3.313rem;
    border: none;

    i {
      font-size: 2rem;
    }
  }

  &.warning {
    background-color: $golden;
  }
}

.center-btn {
  /* position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 1.938rem; */
}

.custom-btn-new {
  height: 3rem;
  width: fit-content;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.875rem;
  gap: 0.625rem;
  font: normal $font-weight-600 1rem/1.5rem $secondary-font;
  letter-spacing: 0.02em;
  margin-left: 1.5rem;

  &.btn-primary {
    background: $turquoise;
    border: none;
    color: $white;
  }

  &.btn-secondary {
    background: $white  !important;
    border: 1px solid $turquoise;
    color: $turquoise  !important;
  }
}

.btn-text-container {
  display: flex;

  .text {
    align-self: center;
    padding-left: 0.5rem;

    &.color-white {
      color: $white;
    }
  }

  &.disabled {
    .text {
      opacity: $disabled-opacity;
    }
  }
}

.portal-status-chip {

  &.status-text,
  .status-text {
    padding: 0.313rem 0.75rem;
    border-radius: 1rem;
    border: 1px solid;
    margin-left: 0.5rem;

    &.online {
      color: $turquoise;
      border-color: $turquoise;
      background-color: $turquoise-bg;
    }

    &.accepted {
      color: $success;
      border-color: $success;
      background-color: $success-bg;
    }

    &.in-connection {
      color: $success;
      border-color: $success;
      background-color: $success-bg;
    }

    &.offline {
      color: $danger;
      border-color: $danger;
      background-color: $danger-bg;
    }

    &.declined {
      color: $coral-red;
      border-color: $coral-red;
      background-color: $danger-bg;
    }

    &.pending-activation {
      color: $warning;
      border-color: $warning;
      background-color: $warning-bg;
    }

    &.pending-invite-reply {
      color: $golden;
      border-color: $golden;
      background-color: $warning-bg;
    }

    &.maintenance {
      color: $light-grey;
      border-color: $light-grey;
      background-color: $light-grey-bg;
    }
  }

  .guest-status {
    padding: 0.063rem 0.7012rem;
  }
}

.custom-pill {
  &.c-switch {
    height: 32px;
    width: 51px;
  }

  &.c-switch-primary {
    .c-switch-input+.c-switch-slider {
      background-color: $light-grey;
      border: none !important;
    }

    .c-switch-input+.c-switch-slider::before {
      top: 3px;
      left: 3px;
      width: 27px;
      height: 27px;
      border: none !important;
    }

    .c-switch-input:checked+.c-switch-slider {
      background-color: $turquoise;
      border: none !important;
    }

    .c-switch-input:checked+.c-switch-slider::before {
      top: 3px;
      left: 8px;
      width: 27px;
      height: 27px;
      border: none !important;
    }
  }

  .c-switch-input {

    &:focus,
    &:hover,
    &:active,
    &.active,
    &:visited {
      ~.c-switch-slider {
        box-shadow: none !important;
      }
    }
  }
}

.pill-container {
  display: inline-flex;

  .pill-text {
    margin-bottom: 0 !important;
    color: $light-grey  !important;
    height: 1.6rem;
  }
}
