.custom-input {
  label {
    color: $black-1;
  }

  /* commented out cause conflicting text area description and not being used*/
  // textarea.form-control {
  //    height: auto !important;
  // }

  .form-control,
  .custom-file-label {
    font: normal $font-weight-400 1rem/1.625rem $secondary-font;
    letter-spacing: 0.002em;
    padding: 0.75rem 20px;
    border-radius: 4px;
    height: 3.75rem;
    border: 1px solid $light-grey;
    color: $mild-dark  !important;

    &:focus,
    &:active,
    &.active,
    &:hover {
      color: $black-1  !important;
      background-color: $white;
      border-color: $bg-500;
      outline: 0;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      border: 1px solid $grey-2;
      background-color: $netural;
    }

    option {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
        4px 0px 4px 0px rgba(0, 0, 0, 0.05),
        -4px 0px 4px 0px rgba(0, 0, 0, 0.05);
    }

  }

  .custom-textarea {
    resize: none;
    height: 130px;
  }

  .input-group-append {
    margin-left: 0px !important;

    .input-group-text {
      background: none;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border: 1px solid $primary-2;
      border-left: none;
      margin-left: 0px;
      padding: 0.3rem 0.75rem;

    }
  }

  &.search-input-container {
    .btn-circle {
      border-radius: 24px !important;
      margin-left: -3rem !important;
      z-index: 9;
      height: 3rem;
      width: 3rem;

      i {
        font-size: 20px;
      }
    }

    .custom-search-input {
      width: 24.438rem;
      height: 3rem !important;
      border: none !important;
      border-radius: 24px !important;
      padding: 0.75rem 1rem;
    }

    .custom-drop-down {
      width: 9.25rem;
    }

    .custom-label {
      margin-bottom: 0;
      margin-top: 0.4rem;
      margin-right: 0.5rem;
    }
  }

  &.checkbox-input-container {
    padding-left: 1.25rem;
    gap: 0.604rem;
    align-items: center;

    .custom-checkbox {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid $light-grey;
      background-color: transparent;
      transition: all 0.15s;
    }

    .custom-checkbox:focus {
      outline: none;
      box-shadow: none;
    }

    .custom-checkbox:checked {
      border-color: $turquoise;
      background-color: $turquoise;
    }

    .custom-checkbox:disabled {
      border-color: $light-grey;
      background-color: $light-grey-bg;
    }

    .custom-label {
      letter-spacing: 0.002em;
      padding: 0;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .custom-search-input {
    width: auto !important;
  }
}
