// chart
.custom-chart{
    &.chart-wrapper {
        margin-top: -2.7rem;
        .chart-number {
            top: 5.7rem;
            left: 1.3rem;
            width: 8rem;
        }
        canvas {
            height: 161px;
            width: 161px;
        }
    }
}