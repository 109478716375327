// sidebar
.custom-modal {
    .modal-content {
        border: none !important;
        width: 37.188rem;  //595px
        max-height: 25.438rem;
        border-radius: 0.5rem !important;
    }
    .modal-header {
        background-color: transparent !important;
        color: $primary-2;
        border: none;
        padding: 1.5rem 1.5rem 0 1.5rem;
        .modal-close-header {
            border: 1px solid $primary-2;
            height: 1.5rem;
            width: 1.5rem;
            text-align: center;
            cursor: pointer;
            &:hover {
                background: $grey-lightest !important;
            }
        }
    }
    .modal-body {
        padding: 1.5rem 1.5rem 1rem 1.5rem;
    }
    .modal-footer {
        border: none;
        justify-content: center !important;
        column-gap: 0.75rem;
    }
}