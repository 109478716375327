@import 'custom/cards';
@import 'custom/button';
@import 'custom/chart';
@import 'custom/breadcrumbs';
@import 'custom/custom_sidebar';
@import 'custom/theme';
@import 'custom/font_size';
@import 'custom/modal';
@import 'custom/header';
@import 'custom/toast';
@import 'custom/input';
@import 'custom/slider';
@import 'custom/popover';
@import './variables';

// Here you can add other styles

body {
    background-color: $grey-2;;
}

.custom-portal-select {
  max-height: 400px;
  overflow-y: auto;
}

.custom-portal-select::-webkit-scrollbar{
  display: none;
}

.custom-portal-checkbox{
  // position: absolute;
  margin: 0px .3rem;
  height: 1rem;
  width: 1rem;
}

.custom-hyper-ref {
  text-decoration: none;
  color:$black-1;
}

.custom-hyper-ref:hover {
  text-decoration: none;
  color: $grey-dark;
  cursor: pointer;
}
.custom-dropdown-menu {
    height: auto;
    max-height: 180px;
    overflow: scroll;
}

.example {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.custom-dropdown-menu::-webkit-scrollbar {
    display: none;
}

.custom-app-update-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: $turquoise;
    margin-right: 18px;
}

.custom-app-update-btn {
    background: linear-gradient(167.96deg, #05103a 0%, #0c1433 49.79%, #000000 100%);
    border-radius: 24px;
    padding: 8px 24px;
    color: $white;
    margin-right: 10px;
    font-weight: 600;
}

.custom-app-update-btn:hover {
    background: linear-gradient(167.96deg, #020820 0%, #0c1433 49.79%, #000000 100%);
    color: $white;
}

.custom-app-update-btn-outline {
    margin-left: 10px;
    color: #05103a;
    border-radius: 24px;
    padding: 8px 24px;
    border: 1px solid #05103a;
    font-weight: bolder;
}
.custom-app-update-btn-outline:hover {
    border: 1px solid #05103a;
    background-color: $grey-2;;
}

.custom-app-update-modal-body {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
}

.custom-app-update-modal-title {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $black-1;
    font-size: 16px;
    letter-spacing: 0.005em;
}

.custom-toggle-switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 31px;
}

.custom-switch-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $light-grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.custom-slider:before {
    position: absolute;
    content: '';
    height: 27px;
    width: 27px;
    left: 3px;
    bottom: 2px;
    // top: 4px;
    background-color: $white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.custom-switch-input:checked + .custom-slider {
    background-color: $turquoise;
}

.custom-switch-input:focus + .custom-slider {
    box-shadow: 0 0 1px $turquoise;
}

.custom-switch-input:checked + .custom-slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

.custom-slider.custom-slider-round {
    border-radius: 34px;
}

.custom-slider.custom-slider-round:before {
    border-radius: 50%;
}

// font

.rti--container {
    flex: 1 !important;
    border-left: none !important;
}

.rti--tag {
    --rti-tag-remove: $white !important;
    --rti-main: #318f2e !important;
    background: $dark-grey !important;
    border-radius: 4px !important;
    color: $white !important;
    font-size: 12px;
}

.rti--tag button {
    color: $white;
}
.rti--tag button :hover {
    color: $white;
}

.rti--input {
    padding: 10px 20px 10px 0px !important;
}

.input-group-prepend{
  .input-group-text{
    border-color: $light-grey !important;
  }
}

h1,
.heading1-primary {
    /*32px/38.4px*/
    font: normal $font-weight-700 2rem/2.4rem $primary-font;
    letter-spacing: 0.005em;
}

h2,
.heading2-primary {
    /*28px/33.6px*/
    font: normal $font-weight-700 1.75rem/2.1rem $primary-font;
    letter-spacing: 0.005em;
}

h3,
.heading3-primary {
    /*24px/28.8px*/
    font: normal $font-weight-700 1.5rem/1.8rem $primary-font;
    letter-spacing: 0.005em;
}

h4,
.heading4-primary {
    /*20px/24px*/
    font: normal $font-weight-700 1.25rem/1.5rem $primary-font;
    letter-spacing: 0.005em;
}

h5,
.heading5-primary {
    /*20px/24px*/
    font: normal $font-weight-600 1.25rem/1.5rem $primary-font;
    letter-spacing: 0.005em;
}

h6,
.heading6-primary {
    /*18px/21.6px*/
    font: normal $font-weight-600 1.125rem/1.35rem $primary-font;
    letter-spacing: 0.005em;
}

.body1-primary {
    /*16px/24px*/
    font: normal $font-weight-400 1rem/1.5rem $primary-font;
}

.body2-primary {
    /*16px/26px*/
    font: normal $font-weight-600 1rem/1.6875rem $primary-font;
    letter-spacing: 0.002em;
}

.body3-primary {
    /*12px/20px*/
    font: normal $font-weight-600 0.75rem/1.25rem $primary-font;
    letter-spacing: 0.002em;
}

.body5-primary {
    /*12px/20px*/
    font: normal $font-weight-400 0.75rem/1.25rem $primary-font;
    letter-spacing: 0.002em;
}

.body6-primary {
    /*12px/14px*/
    font: normal $font-weight-600 0.75rem/0.875rem $primary-font;
    letter-spacing: 0.005em;
}

.heading1-secondary {
    /*28px/34px*/
    font: normal $font-weight-600 1.75rem/2.125rem $secondary-font;
}

.heading2-secondary {
    /*48px/60px*/
    font: normal $font-weight-600 3rem/3.875rem $secondary-font;
}

.heading3-secondary {
    /*32px/38.4px*/
    font: normal $font-weight-700 2rem/2.4rem $secondary-font;
}

.heading4-secondary {
    /*20px/24px*/
    font: normal $font-weight-700 1.25rem/1.5rem $secondary-font;
}

.custom1-primary {
    /*12px/24px*/
    font: normal $font-weight-600 0.75rem/1.5rem $primary-font;
    letter-spacing: 0.02em;
}

.body1-secondary {
    /* 18px/28px */
    font: normal $font-weight-600 1.125rem/1.75rem $secondary-font;
    letter-spacing: 0.002em;
}

.body2-secondary {
    /* 16px/26px */
    font: normal $font-weight-600 1rem/1.625rem $secondary-font;
    letter-spacing: 0.002em;
}

.body3-secondary {
    /* 16px/26px */
    font: normal $font-weight-400 1rem/1.625rem $secondary-font;
    letter-spacing: 0.002em;
}

.body4-secondary {
    /* 14px/22px */
    font: normal $font-weight-400 0.875rem/1.375rem $secondary-font;
    letter-spacing: 0.002em;
}

.body5-secondary {
    /* 12px/20px */
    font: normal $font-weight-400 0.75rem/1.25rem $secondary-font;
    letter-spacing: 0.002em;
}

a {
    font: normal $font-weight-400 0.875rem/1.375rem $secondary-font;
    letter-spacing: 0.01em;
}

.color-primary-1 {
    color: $primary-1;
}

.color-primary-2 {
    color: $primary-2;
}

.color-secondary-1 {
    color: $secondary-1;
}

.color-secondary-2 {
    color: $secondary-2;
}

.color-secondary-3 {
    color: $secondary-3;
}

.color-neutral {
    color: $netural;
}

.bg-turquoise {
    background: $turquoise;
}

.bg-golden {
    background: $golden;
}

.bg-coral-red {
    background: $coral-red;
}

.bg-dark-grey {
    background: $dark-grey;
}

.bg-apple-green {
    background: $apple-green;
}

.bg-100 {
    background: $bg-100;
}

.bg-200 {
    background: $bg-200;
}

.bg-300 {
    background: $bg-300;
}

.bg-400 {
    background: $bg-400;
}

.bg-secondary-1 {
    background: $secondary-1;
}

.bg-secondary-2 {
    background: $secondary-2;
}

.bg-secondary-3 {
    background: $secondary-3;
}

.bg-neutral {
    background: $netural;
}

.border-1 {
    border: 0.0625rem solid $primary-2;
}

.wper-5 {
    width: 5%;
}

.wper-7 {
    width: 7%;
}

.wper-18 {
    width: 18%;
}

.wper-9 {
    width: 9%;
}

.wper-10 {
    width: 10%;
}

.wper-14 {
    width: 14%;
}

.wper-15 {
    width: 15%;
}

.wper-20 {
    width: 20%;
}

.wper-75 {
    width: 75%;
}

.wper-80 {
    width: 80%;
}

.wper-85 {
    width: 85%;
}

.wper-90 {
    width: 90%;
}

.text-underline {
    text-decoration: underline;
}

.lh-0 {
    line-height: 0 !important;
}

// the box with smileys
.over-all-rating {
    padding: 1.175rem 1.69rem;
    background-color: $secondary-1 !important;
    display: flex;

    .heading-text {
        width: 8.691%;
    }

    .smiley {
        width: 17.506%;
    }
}

// user score table
.overall-event-quality {
    .header {
        padding: 0.815rem 0.69rem;
        margin-bottom: 1.8rem;
        background-color: $secondary-3;
    }

    .section {
        padding: 0.815rem 0.025rem;
        height: calc(75vh - 50px);
        overflow: auto;

        &.auto-height {
            height: auto !important;
        }

        .custom-progress {
            padding-right: 3.7rem;
        }

        .user-name-mobile {
            word-wrap: break-word;
        }

        /* width */
        &::-webkit-scrollbar {
            width: 3.11px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 2px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        .content {
            .primary {
                height: 0.625rem;

                .progress-bar {
                    background-color: $secondary-1 !important;
                    border-radius: 0px 0.38rem 0.38rem 0px;
                    font-weight: $font-weight-600;
                }
            }

            .secondary {
                height: 0.625rem;

                .progress-bar {
                    background-color: $primary-2 !important;
                    border-radius: 0px 0.38rem 0.38rem 0px;
                    font-weight: $font-weight-600;
                }
            }

            &:hover {
                border: $hover-border;
                box-sizing: $hover-box-sizing;
                box-shadow: $hover-box-shadow;
                cursor: pointer;
            }

            &.disabledClick:hover {
                cursor: default;
                border: none !important;
                box-sizing: $hover-box-sizing;
                box-shadow: none !important;
            }
        }
    }
}

.chart-container {
    canvas {
        height: 15rem;
    }

    box-shadow: 0px 2px 2px rgba(105, 105, 105, 0.25);
    height: 16.65rem;

    .header {
        background-color: $secondary-3;
        padding: 0.38rem 1.69rem;
        width: 19rem;
        height: 2.5rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
    }
}

// header
/* .custom-header {
    .header-nav-a {
        .bread-c {
            color: $white;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
            &:disabled, &.disabled {
                text-decoration: none !important;
            }
        }
        .icon {
            color: $white;
            font-size: 0.7rem;
        }
    }
    .custom-anchor {
        pointer-events: none;
        text-decoration: none;
    }
    .pr-0-65 {
        padding-right: 0.65rem !important;
    }
    .px-0-75 {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
    .mx-0-65 {
        margin-left: 0.65rem !important;
        margin-right: 0.65rem !important;
    }
    .custom-header-right {
        .avatar-chevron {
            padding-right: 0rem !important;
            padding-left: 0.65rem !important;
        }
        a,
        a:hover,
        a:active,
        a:focus,
        a:visited {
            color: $primary-2 !important;
        }
    }
    .custom-icon-header {
        padding: 0 !important;
        color: $white !important;
    }
} */

// color temporary blocks
.color-block {
    height: 6.25rem;
    width: 6.25rem;
}

.color-block-big {
    height: 6.25rem;
    width: 12.5rem;
}

//body
.c-main {
    padding: 1.3rem 2.875rem 0rem 2.875rem !important;
}

@media screen and (max-width: 768px) {
    // *:not(.overall-event-quality) {
    //     box-sizing: content-box;
    // }
}

.c-app {
    min-height: 100%;

    .c-wrapper {
        background: $light-grey-2;

        .toggler {
            height: 0rem;
            margin-left: -1rem;
            z-index: 9999;
            /* position: relative;
      top: 1.3rem; */
            top: 6.6rem;
            position: fixed;

            .cont {
                cursor: pointer;
                height: 2rem;
                width: 2rem;
                background: $turquoise;
                border-radius: 1rem;
                padding: 0.3rem 0.4rem 0.1rem 0.4rem;

                svg {
                    color: $white;
                }
            }
        }
    }
}

.custom-img {
    width: 100%;
    height: auto;
}

.custom-img-small {
    border: 1px solid $grey-1;
    width: 5rem;
    height: 5rem;
}

.custom-table {
    &.table {
        border: none !important;
    }

    &.table th {
        border: none !important;
        font: normal $font-weight-700 1rem/1.5rem $primary-font;
    }

    &.table td {
        vertical-align: middle;
        border: none !important;
        font: normal $font-weight-400 1rem/1.5rem $primary-font;
    }

    .edit {
        &:hover {
            background: rgba(0, 0, 0, 0.05) !important;
            cursor: pointer;
        }
    }
}

.custom-link {
    text-decoration: underline !important;
    color: $hyperlink;
    cursor: pointer;
    word-break: break-all;
}

.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
}

.white {
    color: $white;
}

.white-important {
    color: $white !important;
}

.danger-important {
    color: $coral-red !important;
}

.dark-grey {
    color: $dark-grey;
}

.turquoise {
    color: $turquoise;
}

.black-1 {
    color: $black-1;
}

/* scrollbar class */
.custom-scrollbar {
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
        border-radius: 1.875rem;

        &:hover {
            background-color: $light-grey;
            border-radius: 1.875rem;
        }
    }

    &::-webkit-scrollbar {
        width: 0.75rem;
    }

    &.dark {
        &::-webkit-scrollbar-track {
            background-color: $scrollbar-track-dark;
        }
    }

    &.light {
        &::-webkit-scrollbar-track {
            background-color: $scrollbar-track-light;
        }
    }
}

/* Page Class : Connections Schedule Page */
.page-body {
    margin-top: 80px;
}

/* padding */
.padding-0 {
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

/* Modal Design UI */
.modalUi {
    .modaTitle {
        font-size: 1.25rem;
        font-weight: 600;
    }

    .modal-body {
        overflow-x: hidden;
        padding: 2.5rem;
    }
}



/* Custom Seatch */
.custom-search {
    position: relative;

    .search-icon {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        color: $light-grey;
        pointer-events: none;
    }

    .form-control {
        padding-right: 3.75rem;
    }
}

/* Custom selectbox */
.custom-selectbox {
    position: relative;

    .chevron-down {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        color: $light-grey;
        pointer-events: none;
    }

    .form-control {
        padding-right: 3.75rem;
    }
}

.slot-div {
    float: left;
    width: 730px;
    // height: 60px;
    max-height: 106px;
    overflow-y: auto;
}

.custom-datepicker {
    position: relative;

    .datepicker-icon {
        background-color: $white;
        position: absolute;
        top: 50%;
        right: 1.3rem;
        transform: translateY(-50%);
        color: $light-grey;
        pointer-events: none;
    }
}

/* Custom Text Radio Button */

.custom-text-checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: relative;
    padding: 0 1rem !important;
    border: 1px solid $light-grey;
    border-radius: 4px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        opacity: 0;
        z-index: 1;
        cursor: pointer;

        & + .form-check-label {
            color: $light-grey;
            font-size: 16px;
            font-weight: 400;

            > span {
              position: relative;
              z-index: 1;
              cursor: pointer;
            }
          }

          &:checked + .form-check-label {
            color: $primary-1;
            &:before {
                content: '';
                background-color: $dark-grey;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0 !important;
                z-index: 1;
                cursor: pointer;
                outline: 1px solid $dark-grey;
                border-radius: 4px;
            }
        }
    }
}

.select-tag {
    background: $dark-grey;
    border: 1px solid $dark-grey;
    border-radius: 4px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    padding: 0 0.5rem;
    font-size: 12px;

    .select-tag-name {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.custom-select-dropdown {
    width: 100%;
    background: $white;
    position: absolute;
    z-index: 2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    4px 0px 4px 0px rgba(0, 0, 0, 0.05),
    -4px 0px 4px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #bdced8;
    border-radius: 4px;
    margin-top: -5px;

    .select-option {
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      border-radius: 4px;
        &:hover {
            background-color: $list-hover;
        }
    }
}

.pending-portal {
  border: 1px solid $golden;
  font-size: 12px;
  border-radius: 1rem;
  padding: 4px .5rem;
  color: $golden;
  font-weight: $font-weight-600;
  background: $warning-bg;
}
.disconnected-portal {
  border: 1px solid $light-grey;
  font-size: 12px;
  border-radius: 1rem;
  padding: 4px .5rem;
  color: $light-grey;
  font-weight: $font-weight-600;
  background: $light-grey-bg;
}

@media screen and (max-width: 767px) {
    .modalUi {
        .modal-dialog {
          max-width: 100vw !important;
        }
        .modal-dialog-scrollable {
          height: calc(100vh - 1rem);

          .modal-content {
            max-height: 100%;
            overflow: hidden;
          }
        }
        .modal-body {
            padding: 1.2rem;
            // overflow-y: scroll !important;
        }
    }
}

.dropdown-item {
    &:active,
    &:hover {
        background: $grey-2 !important;
        color: $black-1 !important;
    }
}

.mandatory-field {
  position: relative;

  > .mandatory {
    font-size: 1.5rem;
    color: $coral-red;
    position: absolute;
    top: 15%;
  }
}

.mobile-btn {
  padding: 0 !important;
  box-sizing: border-box !important;
  height: 48px;
  width: 48px;
  border: none !important;
  border-radius: 50% !important;
}
