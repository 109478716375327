.custom-header {
    background-color: $white !important;
    top:0;
    height: 80px;
    border:none;
    .dropdown-menu{
        padding:0;
        .dropdown-item {
            &:hover{
                text-decoration: none;
                color: #455164;
                background: rgba(0, 0, 0, 0.05);
                border-radius: 0.5rem;
            }
        }
    }
    .c-avatar{
      width: 3rem;
      height: 3rem;
      margin-right: 0.75rem;
    }
    .chevron-down{
      margin-left: 1.5rem;
      svg {
        color:$black-1;
      }
    }
    .avatar-name{
      color: $black-1;
      letter-spacing: 0.005em;
      text-transform: capitalize;
    }
    &.user-profile{
      color: $white !important;
      cursor: pointer;
      &.custom-header {
        background-color: transparent !important;
        position: relative;
        height: inherit;
        bottom: 1rem;
        align-self: center;
        .c-avatar{
          width: 3rem;
          height: 3rem;
          margin-right: 0.75rem;
          .c-avatar-img {
            border: none !important;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            border: 1px solid;
            background: $turquoise;
          }
        }
      }
    }
}
